import { Session, UserAccount } from '@piccadilly-cloud/connect-platform-services';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { Location } from 'react-router';

export const analytics = new AnalyticsBrowser();
export const initSegment = (session: Session) => {
  try {
    const writeKey = process.env.REACT_APP_SEGMENT_TOKEN;
    if (!writeKey) {
      throw new Error('token not found');
    }

    const host = process.env.REACT_APP_EDGE_URL;
    if (!host) {
      throw new Error('host not found');
    }

    const [protocol, baseURL] = host.split('://');
    analytics.load({ writeKey }, {
      integrations: {
        'Segment.io': {
          apiHost: `${baseURL}/v1/segment`,
          protocol: protocol as 'https' | 'http',
          deliveryStrategy: {
            strategy: 'standard',
            config: {
              headers: {
                'Content-Type': 'application/json',
                Authorization: session.token,
              },
            },
          },
        },
      },
    });
  } catch (error) {
    console.error('Error initializing Segment', error);
  }
};

export const trackPageViewSegment = (nextLocation: Location, prevLocation?: Location) => {
  // Delay tracking page views for performance.
  setTimeout(() => {
    analytics.page('Navigate', {
      path: nextLocation.pathname,
      referrer: prevLocation?.pathname,
    });
  }, 500);
};

export const identifyAnonymousUserSegment = () => {
  analytics.identify();
};

export const getSegmentIdentityFromState = (user: UserAccount) => {
  const {
    accountRole,
    personalInfo,
    email,
  } = user;
  const { firstName, lastName } = personalInfo;
  const name = `${firstName} ${lastName}`;
  return {
    email,
    name,
    role: accountRole,
  };
};

export const identifyAuthenticatedUserSegment = (user: UserAccount) => {
  const segmentIdentity = getSegmentIdentityFromState(user);
  console.info('Identifying user', user.id);
  analytics.identify(user.id, segmentIdentity);
};

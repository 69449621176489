import { PopoverOrigin } from '@mui/material';

interface PopoverPosition {
  style?: Record<string, unknown>;
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

export function getPosition(arrow: string): PopoverPosition {
  const props: PopoverPosition = {
    style: {},
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    transformOrigin: { vertical: 'top', horizontal: 'right' },
  };

  switch (arrow) {
    case 'top-left':
      props.style = { ml: -0.75 };
      props.anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
      props.transformOrigin = { vertical: 'top', horizontal: 'left' };
      break;
    case 'top-center':
      props.anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
      props.transformOrigin = { vertical: 'top', horizontal: 'center' };
      break;
    case 'top-right':
      props.style = { ml: 0.75 };
      props.anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
      props.transformOrigin = { vertical: 'top', horizontal: 'right' };
      break;
    case 'bottom-left':
      props.style = { ml: -0.75 };
      props.anchorOrigin = { vertical: 'top', horizontal: 'left' };
      props.transformOrigin = { vertical: 'bottom', horizontal: 'left' };
      break;
    case 'bottom-center':
      props.anchorOrigin = { vertical: 'top', horizontal: 'center' };
      props.transformOrigin = { vertical: 'bottom', horizontal: 'center' };
      break;
    case 'bottom-right':
      props.style = { ml: 0.75 };
      props.anchorOrigin = { vertical: 'top', horizontal: 'right' };
      props.transformOrigin = { vertical: 'bottom', horizontal: 'right' };
      break;
    case 'left-top':
      props.style = { mt: -0.75 };
      props.anchorOrigin = { vertical: 'top', horizontal: 'right' };
      props.transformOrigin = { vertical: 'top', horizontal: 'left' };
      break;
    case 'left-center':
      props.anchorOrigin = { vertical: 'center', horizontal: 'right' };
      props.transformOrigin = { vertical: 'center', horizontal: 'left' };
      break;
    case 'left-bottom':
      props.style = { mt: 0.75 };
      props.anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
      props.transformOrigin = { vertical: 'bottom', horizontal: 'left' };
      break;
    case 'right-top':
      props.style = { mt: -0.75 };
      props.anchorOrigin = { vertical: 'top', horizontal: 'left' };
      props.transformOrigin = { vertical: 'top', horizontal: 'right' };
      break;
    case 'right-center':
      props.anchorOrigin = { vertical: 'center', horizontal: 'left' };
      props.transformOrigin = { vertical: 'center', horizontal: 'right' };
      break;
    case 'right-bottom':
      props.style = { mt: 0.75 };
      props.anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
      props.transformOrigin = { vertical: 'bottom', horizontal: 'right' };
      break;
    default:
      break;
  }

  return props;
}

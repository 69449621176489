import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Iconify from 'src/components/iconify';
import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
import { usePayments, useWorkspace } from 'src/hooks';
import { useNavConfig } from 'src/routes/navigation/useNavConfig';
import { paths } from 'src/routes/paths';
import { hideScroll } from 'src/theme/mui/css';

import { NavToggleButton } from '../_common';
import NavPoweredBy from '../_common/nav-powered-by';
import { NAV } from '../config-layout';

export default function NavMini() {
  const { isBillingEnabled } = usePayments();
  const ws = useWorkspace();

  const navData = useNavConfig();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini
          data={navData.data}
          config={navData.config}
        />

        <Box sx={{ flexGrow: 1 }} />
        {/* session.account.accountRole === 'WORKSPACE_ADMIN' */}
        <NavSectionMini
          config={{
            itemRadius: 12,
          }}
          data={[{
            subheader: '',
            items: [
              ...(isBillingEnabled ? [{
                title: 'Billing',
                path: paths.billing,
                icon: <Iconify icon="mdi:credit-card-settings" />,
              }] : []),
              {
                title: 'Users',
                path: paths.recruiter.members.root(ws.index),
                icon: <Iconify icon="mdi:account-multiple" />,
              },
              {
                title: 'Support',
                path: process.env.REACT_APP_ZENDESK_HC_URL || '#',
                icon: <Iconify icon="mdi:help-circle" />,
              },
            ],
          }]}
        />

        <NavPoweredBy />
      </Stack>
    </Box>
  );
}

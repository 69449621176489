import services, { UserAccountAnalyticsInfo } from '@piccadilly-cloud/connect-platform-services';

import { useState } from 'react';

// Dep cycle
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { useApplicationFlowContext, useSessionContext } from 'src/contexts';
import { useLoading } from 'src/hooks';

import { SurveyFlowStepConfig } from './model';
import { useSurveyFlow } from './use-survey-flow';

export default function SurveyFlow() {
  const session = useSessionContext();
  const applicationFlowContext = useApplicationFlowContext();
  const loading = useLoading();

  const {
    getBackStepInOrder,
    getInitialSurveyFlowStep,
    getNextStepInOrder,
  } = useSurveyFlow();
  const [currentStep, setCurrentStep] = useState<SurveyFlowStepConfig | null>(getInitialSurveyFlowStep());

  const onNext = async (updatedAnalytics?: UserAccountAnalyticsInfo) => {
    if (!currentStep) {
      return;
    }

    try {
      loading.start();
      if (updatedAnalytics) {
        const updatedUserAccount = await services.edge.app.userAccount.updateAnalyticsInfo(
          session.account.email,
          updatedAnalytics,
        )({ token: session.token });
        session.updateCurrentAccount(updatedUserAccount);
      }

      const nextStep = getNextStepInOrder(currentStep.id);
      if (!nextStep) {
        applicationFlowContext.dispatch['applicationFlow/setIsSurveyComplete']({
          isSurveyComplete: true,
        });
      }

      setCurrentStep(nextStep);
    } catch (error) {
      console.error(error);
    } finally {
      loading.stop();
    }
  };

  const onPrevious = () => {
    if (!currentStep) {
      return;
    }

    const backStep = getBackStepInOrder(currentStep.id);
    if (!backStep) {
      return;
    }

    setCurrentStep(backStep);
  };

  return (
    <CustomDialog
      open
      maxWidth="sm"
      hideTitle
      hideCancel
      hideConfirm
    >
      {currentStep && (
        <currentStep.component
          onNext={onNext}
          onPrevious={onPrevious}
        />
      )}
    </CustomDialog>
  );
}

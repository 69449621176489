import { AppConfigWorkspace } from '@piccadilly-cloud/connect-platform-services';

import { useMemo } from 'react';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

const defaultWorkspace: AppConfigWorkspace = {
  createdAt: new Date(),
  defaultCompanyProfile: '',
  deleted: false,
  departments: [],
  id: '',
  isPreviewWorkspace: false,
  name: '',
  updatedAt: new Date(),
  vendor: '',
};

interface ReturnType {
  index: number;
  workspace: AppConfigWorkspace;
}

export const useWorkspace = (): ReturnType => {
  const session = useSessionContext();

  const data = useMemo(() => {
    let index = -1;
    if (session.activeWorkspace !== -1) {
      index = session.activeWorkspace;
    }

    if (!session.workspaces || session.workspaces.length < index + 1) {
      index = -1;
    }

    if (index === -1) {
      return {
        index,
        workspace: defaultWorkspace,
      };
    }

    const workspace = session.workspaces[index];
    return {
      index,
      workspace,
    };
  }, [session]);

  return data;
};

import { OnboardingDataResponse, PostRegistrationOnboardingAction } from '@piccadilly-cloud/connect-platform-services';

import { CustomDialogProps } from 'src/components';

import { ContextAction, ContextDispatch } from '../model';

export enum OnboardingFlowType {
  PURCHASE = 'PURCHASE',
  PREPURCHASE = 'PREPURCHASE',
}

interface OnboardingStepButton {
  label: string;
  href?: string;
  modal?: (props: CustomDialogProps) => JSX.Element;
}

interface WelcomeDialogFeatures {
  title: string;
}

export interface WelcomeDialogConfig {
  header: string;
  body: string;
  features?: WelcomeDialogFeatures[];
  ctaLine?: string;
  buttonText: string;
}

export interface OnboardingStep {
  label: string;
  description?: string;
  useExpand: boolean;
  disabled?: boolean;
  isComplete: boolean;
  button?: OnboardingStepButton;
  event: PostRegistrationOnboardingAction;
}

export type OnboardingFlowConfiguration = Record<OnboardingFlowType, OnboardingStep[]>;

export type OnboardingFlowState = {
  data: OnboardingDataResponse;
  dispatch: OnboardingFlowDispatch;
  isAvailable: boolean;
  allStepsComplete: boolean;
  completeCtaEnabled: boolean;
  isOpen: boolean;
  steps: OnboardingStep[];
  welcomeDialog: WelcomeDialogConfig;
};

export type OnboardingFlowAction =
  | ContextAction<'onboardingFlow/SET_DISPATCH', { dispatch: OnboardingFlowDispatch }>
  | ContextAction<'onboardingFlow/INIT_ONBOARDING', {
    data: OnboardingDataResponse;
    steps: OnboardingStep[];
    isAvailable: boolean;
    allStepsComplete: boolean;
    completeCtaEnabled: boolean;
    welcomeDialog: WelcomeDialogConfig;
  }>
  | ContextAction<'onboardingFlow/STEP_COMPLETED', {
    data: OnboardingDataResponse;
    steps: OnboardingStep[];
    allStepsComplete: boolean;
  }>
  | ContextAction<'onboardingFlow/TOGGLE_OPEN', { isOpen: boolean }>
  | ContextAction<'onboardingFlow/ONBOARDING_COMPLETED', { isAvailable: boolean }>;

export interface OnboardingFlowDispatch {
  'onboardingFlow/initState': (payload: {
    data: OnboardingDataResponse;
    steps: OnboardingStep[];
    isAvailable: boolean;
    allStepsComplete: boolean;
    completeCtaEnabled: boolean;
    welcomeDialog: WelcomeDialogConfig;
  }) => Promise<void>;
  'onboardingFlow/setStepComplete': (payload: {
    token: string;
    event: PostRegistrationOnboardingAction;
    data: OnboardingDataResponse;
    steps: OnboardingStep[];
  }) => Promise<void>;
  'onboardingFlow/toggleOpen': (payload: { isOpen: boolean }) => Promise<void>;
  'onboardingFlow/onboardingComplete': (payload: {
    token: string;
    data: OnboardingDataResponse;
  }) => Promise<void>;
}

export type OnboardingFlowContextDispatch = ContextDispatch<
  OnboardingFlowAction,
  OnboardingFlowDispatch
>;

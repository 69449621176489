import { EducationProgress, EducationTypeExt } from '@piccadilly-cloud/connect-platform-services';

import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';

import React, { useEffect } from 'react';

import Iconify from 'src/components/iconify';
import { piccIcon } from 'src/utils';

import listingConfig from '../../config';

type AdditionalEducationSelectProps = {
  index: number;
  onUpdate: (type: EducationTypeExt, prog: EducationProgress) => void;
  type: EducationTypeExt | null;
  progress: EducationProgress | null;
  onRemove: VoidFunction;
  selected?: { type: string; progress: number | null }[];
  fieldError?: string;
  hideRemoveBtn?: boolean;
};

export default function AdditionalEducationSelect(props: AdditionalEducationSelectProps) {
  const { index, onUpdate, onRemove, type, progress, selected, fieldError, hideRemoveBtn } = props;
  const [key, setKey] = React.useState(type ?? '');
  const [value, setValue] = React.useState<number | ''>(progress ?? '');
  const theme = useTheme();

  const handleKeyChange = (event: SelectChangeEvent) => {
    const newKey = event.target.value;

    if (newKey === '') {
      handleRemove();
      return;
    }

    setKey(newKey);
    setValue('');

    onUpdate(newKey as EducationTypeExt, value as EducationProgress);
  };

  const handleValueChange = (event: SelectChangeEvent) => {
    const newValue = Number(event.target.value);
    setValue(newValue);

    onUpdate(key as EducationTypeExt, newValue);
  };

  const isRemoveDisabled = (index === 0 && key === '');
  const isProgressDisabled = key === '';

  const handleRemove = () => {
    onRemove();
    setKey('');
    setValue('');
  };

  useEffect(() => {
    setKey(type ?? '');
    setValue(progress ?? '');
  }, [type, progress]);

  const invalidProgValue = value === null || value === 0 || value === '';
  const isFieldErrEnabled = fieldError
    && (key === '' || invalidProgValue);

  const defaultKeyValDisabled = !hideRemoveBtn || key === '';
  const defaultProgressValDisabled = !hideRemoveBtn || invalidProgValue;

  return (
    <>
      <Box
        display="flex"
        flexDirection={hideRemoveBtn ? 'column' : 'row'}
        rowGap={1}
        columnGap={1}
        sx={{ my: 1 }}
      >
        <FormControl fullWidth size="small">
          <InputLabel id="key-label">Additional Education (optional) </InputLabel>
          <Select
            labelId="key-label"
            label="Additional education (optional) "
            value={key}
            onChange={handleKeyChange}
            size="small"
            sx={{
            }}
          >
            <MenuItem value="" disabled={defaultKeyValDisabled}>
              None
            </MenuItem>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {listingConfig.additionalEducationOpts.map((option) => {
              const isDisabled = selected?.some((item) => item.type === option.value);
              return (
                <MenuItem key={option.value} value={option.value} defaultChecked disabled={isDisabled}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth disabled={!key} size="small">
          <InputLabel
            id="value-label"
            sx={{ opacity: isProgressDisabled ? '20%' : '100%' }}
          >
            Progress
          </InputLabel>
          <Select
            labelId="value-label"
            label="Progress"
            size="small"
            value={value !== null && value !== 0 ? String(value) : ''}
            onChange={handleValueChange}
            disabled={isProgressDisabled}
            sx={{
              opacity: isProgressDisabled ? '20%' : '100%',
            }}
          >
            <MenuItem value={0} disabled={defaultProgressValDisabled}>
              None
            </MenuItem>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {listingConfig.educationProgressOpts.map((option) => (
              <MenuItem key={option.label} defaultChecked value={String(option.value)}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!hideRemoveBtn && (
          <IconButton
            onClick={handleRemove}
            disableRipple
            disabled={isRemoveDisabled}
            sx={{
              cursor: 'pointer',
              position: 'relative',
              zIndex: 1000,
            }}
          >
            <Iconify
              icon={piccIcon.actions.close}
              color={isRemoveDisabled ? theme.palette.grey[500] : theme.palette.grey[700]}
              width={24}
            />
          </IconButton>
        )}
      </Box>
      {isFieldErrEnabled && (
        <Typography variant="body2" color={theme.palette.error.main} sx={{ pt: 1 }}>
          {fieldError}
        </Typography>
      )}
    </>
  );
}

import { Box, Typography, useTheme } from '@mui/material';

import { useParams } from 'react-router';

import Iconify from 'src/components/iconify';
import { removeStorage } from 'src/hooks';
import { piccIcon } from 'src/utils';

import HeaderBanner from '../v2/_common/header-banner';

export default function Banner() {
  const theme = useTheme();
  const { listingId } = useParams<{ listingId: string }>();
  const profileKey = `prof-${listingId}`;

  const handleClosePreview = () => {
    if (listingId) {
      removeStorage(listingId);
      removeStorage(profileKey);
    }
    window.close();
  };

  return (
    <HeaderBanner
      height="76px"
      color={theme.palette.info.lighter}
      disableElevation
      isSticky
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={1}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          columnGap={1}
          sx={{ color: theme.palette.background.paper }}
        >
          <Iconify icon={piccIcon.alertBanner.info} color={theme.palette.info.main} />
          <Typography variant="h6" color={theme.palette.info.darker}>
            This is a preview of the assessment. No answers will be saved.&nbsp;
            <Typography
              variant="h6"
              component="a"
              onClick={handleClosePreview}
              sx={{
                color: theme.palette.primary.main,
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.darker,
                },
              }}
            >
              Close preview
            </Typography>
          </Typography>
        </Box>
      </Box>
    </HeaderBanner>
  );
}

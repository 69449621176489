import services from '@piccadilly-cloud/connect-platform-services';

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Iconify from 'src/components/iconify';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useWorkspace } from 'src/hooks';
import { paths } from 'src/routes/paths';
import piccIcon from 'src/utils/config/icon';

export default function NavConfigRecruiter() {
  const session = useSessionContext();
  const workspaces = useWorkspace();
  const currentIndex = workspaces.index;
  const navigate = useNavigate();

  const [activeNav, setActiveNav] = useState<{
    title: string;
    icon: JSX.Element;
    path: string;
  }[]>([]);

  useEffect(() => {
    if (workspaces.index !== -1) {
      services.edge.job.listing.getActiveSummaries(workspaces.workspace.id, { limit: 5 })({ token: session.token })
        .then((res) => {
          // setAssessments(res);
          const an = res.items.map((i) => ({
            title: i.label,
            path: paths.recruiter.workspace.jobDashboard.root(workspaces.index, i.id),
            icon: <Iconify icon="mdi:clipboard-pulse-outline" />,
          }));
          setActiveNav(an);
        }).catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.workspace.id]);

  const handleViewAllDashboards = useCallback(() => {
    navigate(paths.recruiter.workspace.dashboard(workspaces.index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.index]);

  const workspaceRoutes = {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        path: paths.recruiter.workspace.dashboard(workspaces.index),
        icon: <Iconify icon="ic:round-home" />,
        children: activeNav.length !== 0 ? activeNav : undefined,
        viewAllAction: activeNav.length >= 5 ? handleViewAllDashboards : undefined,
      },
      {
        title: 'Candidates',
        path: paths.recruiter.workspace.candidates.root(workspaces.index),
        icon: <Iconify icon="mdi:account-group" />,
      },
      {
        title: 'Assessments',
        path: paths.recruiter.workspace.jobs.root(workspaces.index),
        icon: <Iconify icon="mdi:clipboard-check" />,
        children: [
          {
            title: 'Drafts',
            icon: <Iconify icon="mdi:clipboard-edit-outline" />,
            path: paths.recruiter.workspace.jobs.drafts(workspaces.index),
          },
          {
            title: 'Active',
            icon: <Iconify icon="mdi:clipboard-check-outline" />,
            path: paths.recruiter.workspace.jobs.active(workspaces.index),
          },
          {
            title: 'Closed',
            icon: <Iconify icon="mdi:clipboard-remove-outline" />,
            path: paths.recruiter.workspace.jobs.closed(workspaces.index),
          },
        ],
      },
      {
        title: 'Create',
        path: paths.recruiter.workspace.jobs.profiles(workspaces.index),
        icon: <Iconify icon={piccIcon.actions.add} />,
      },
    ],
  };

  const nonWorkspaceRoutes = {
    subheader: '',
    items: [],
  };

  const items: any = [];
  if (currentIndex !== -1) {
    items.push(workspaceRoutes);
  }
  items.push(nonWorkspaceRoutes);
  return items;
}

import { UserAccountAnalyticsInfo } from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import FormProvider, { RHFRadioGroup } from 'src/components/hook-form';
import { useSessionContext } from 'src/contexts';

import { SurveyFlowStepProps } from '../model';
import DemographicsWrapper from '../wrapper';

import { genderOptions } from './model';

export default function DemographicsGender(props: SurveyFlowStepProps) {
  const { onNext, onPrevious } = props;
  const session = useSessionContext();

  const FormSchema = Yup.object().shape({
    gender: Yup.string().required('Please make a selection'),
  });

  const defaultValues = {
    gender: session.account.analyticsInfo.gender,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const handleSubmit = methods.handleSubmit(async (data) => {
    const updatedAnalytics: UserAccountAnalyticsInfo = {
      gender: data.gender,
      source: '',
      age: '',
      ethnicity: '',
      extendedQuestions: [],
    };
    await onNext(updatedAnalytics);
  });

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit}
    >
      <DemographicsWrapper
        onPrevious={onPrevious}
        isSubmitting={methods.formState.isSubmitting}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h5">
            What is your gender?
          </Typography>
          <Box sx={{ pl: 1 }}>
            <RHFRadioGroup
              name="gender"
              options={genderOptions}
            />
          </Box>
        </Box>
      </DemographicsWrapper>
    </FormProvider>
  );
}

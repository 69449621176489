import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useRouter } from 'src/hooks';
import { paths } from 'src/routes/paths';
import { fToNow } from 'src/utils/format-time';

import { useSessionContext } from '../useSessionContext';

export interface AuthGuardProps {
  children: ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, exp, sessionType } = useSessionContext();

  const router = useRouter();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isAuthenticated || sessionType === 'UNAUTHENTICATED') {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      const loginPath = paths.auth.login;
      const href = `${loginPath}?${searchParams}`;
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router, sessionType]);

  if (exp) {
    const currentTime = Date.now() / 1000;
    if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
      console.info('[session] token checked in auth guard; expires', fToNow(new Date(exp * 1000)));
    }
    if (exp < currentTime) {
      if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
        console.info('[session] token expired; checked in auth guard');
      }
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      const loginPath = paths.auth.login;
      const href = `${loginPath}?${searchParams}`;
      router.replace(href);
    }
  }

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

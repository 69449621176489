import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Typography,
  alpha,
} from '@mui/material';

export default function SurveyWrapper(props: {
  onPrevious: VoidFunction;
  children: JSX.Element;
  hideHeader?: boolean;
  isSubmitting: boolean;
}) {
  const { onPrevious, children, hideHeader, isSubmitting } = props;

  return (
    <Box display="flex" flexDirection="column" rowGap={3}>
      {!hideHeader && (
        <>
          <Box display="flex" flexDirection="row" justifyContent="left">
            <Typography variant="h3">
              Demographics
            </Typography>
          </Box>
          <Typography>
            The following questions are asked to verify that our equal opportunity efforts are
            reaching all segments of the population in accordance to federal laws. This information
            will not be shared with your recruiter or the employer.
          </Typography>
        </>
      )}
      <Card
        sx={{
          boxShadow: `0px 12px 24px -4px ${alpha('#919eab', 0.12)}, 0px 0px 2px 0px ${alpha('#919eab', 0.2)}`,
          borderRadius: 3,
          border: 0,
          p: 2,
        }}
      >

        {children}
      </Card>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        columnGap={1}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            onPrevious();
          }}
        >
          Back
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          loading={isSubmitting}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
}

import { Button, useTheme } from '@mui/material';

export default function FormButton(props: {
  children: React.ReactNode;
  selected: boolean;
  onClick: VoidFunction;
}) {
  const { children, selected, onClick } = props;

  const theme = useTheme();

  return (
    <Button
      size="large"
      onClick={onClick}
      sx={{
        px: 4,
        borderRadius: 3,
        border: `1px solid ${theme.palette.info.lighter}`,
        '&:hover': {
          border: `1px solid ${theme.palette.primary.light}`,
          backgroundColor: theme.palette.primary.lighter,
        },
        ...(selected ? {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.lighter,
          outlineOffset: '-1px',
        } : {}),
      }}
    >
      {children}
    </Button>
  );
}

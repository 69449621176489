import { Link, useTheme } from '@mui/material';

import { getMailTo } from 'src/utils';

export interface ContactUsProps {
  subject?: string;
  capitalize?: boolean;
}

export default function ContactUs(props: ContactUsProps) {
  const { subject, capitalize } = props;
  const theme = useTheme();
  const mailTo = getMailTo(subject);
  const contactUs = capitalize ? 'Contact us' : 'contact us';
  return (
    <Link
      href={mailTo}
      target="_blank"
      style={{ color: theme.palette.primary.main }}
    >
      {contactUs}
    </Link>
  );
}

const alertBanner = {
  info: 'mdi:information',
};

const menu = {
  jobs: 'mdi:briefcase',
};

const other = {
  applicationProfile: 'mdi:clipboard-account',
  arrowRight: 'mdi:arrow-right-thin',
  calendar: 'mdi:calendar',
  chevronDown: 'mdi:chevron-down',
  chevronLeft: 'mdi:chevron-left',
  chevronRight: 'mdi:chevron-right',
  close: 'ion:close-sharp',
  closeModal: 'mdi:close',
  downloadFile: 'mdi:tray-arrow-down',
  drag: 'mdi:drag',
  email: 'mdi:email',
  eyeHide: 'mdi:eye-off',
  eyeShow: 'mdi:eye',
  jobDashboard: 'mdi:briefcase-account',
  jobsListing: 'mdi:briefcase-search',
  link: 'octicon:link-16',
  mapMarker: 'mdi:map-marker',
  sort: 'carbon:chevron-sort',
  workspace: 'mdi:monitor-dashboard',
  dropdown: 'eva:arrow-ios-downward-fill',
};

const actions = {
  add: 'mdi:plus-circle',
  close: 'mdi:close-circle',
  delete: 'fa6-regular:trash-can',
  duplicate: 'ion:duplicate-sharp',
  editDraft: 'mdi:text-box-edit',
  favorite: 'mdi:star',
  lock: 'mdi:lock',
  lockReset: 'mdi:lock-reset',
  more: 'mdi:dots-vertical',
  publish: 'material-symbols:publish',
  remove: 'mdi:minus-circle',
  send: 'mdi:send',
  shareAssessment: 'fluent:mail-link-24-filled',
  statusChange: 'ic:round-bolt',
  unfavorite: 'mdi:star-outline',
  unlock: 'mdi:unlocked-variant',
  previewAssessment: 'mdi:clipboard-search',
};

const traits = {
  traits: 'material-symbols:widgets-rounded',
};

const jobCredentials = {
  certificates: 'fluent:certificate-16-filled',
};

const transcription = {
  block: 'mdi:block',
  microphone: 'mdi:microphone',
  stop: 'material-symbols:stop-circle-outline-rounded',
};

const assessmentCredits = {
  creditsRemaining: 'mdi:clipboard-list',
  zeroRemaining: 'mdi:close-circle-outline',
};

const applicationIcons = {
  agreementPolicy: 'mdi:shield-lock',
  assessment: 'mdi:clipboard-clock',
  assessmentCompleted: 'mdi:checkbox-marked',
  availability: 'mdi:account-clock',
  background: 'mdi:clipboard-account',
  eligibility: 'fluent:contact-card-28-filled',
  review: 'map:search',
  note: 'mdi:text-box',
  overview: 'mdi:cog',
  profile: 'mdi:account',
  share: 'mdi:email-newsletter',
  statusClosed: 'mdi:person-remove',
  statusHired: 'fa6-solid:handshake',
  statusOpen: 'mdi:account-check',
};

const jobStatus = {
  active: 'fluent:live-24-filled',
  closed: 'mdi:close-circle',
};

const candidateStatus = {
  interviewed: 'mdi:people',
  contacted: 'mdi:comment-check',
  overqualified: 'mdi:flag-variant',
};

const psychometrics = {
  adaptingAndCoping: 'material-symbols:change-circle',
  analyzingAndInterpreting: 'mdi:magnify-scan',
  creatingAndConceptualizing: 'mdi:head-idea',
  enterprisingAndPerforming: 'mdi:report-finance',
  interactingAndPresenting: 'mdi:presentation',
  leadingAndDeciding: 'mdi:person-tie',
  organizingAndExecuting: 'mdi:strategy',
  supportingAndCooperating: 'mdi:hand-extended',
  conscientiousness: 'material-symbols:list-alt',
  agreeableness: 'material-symbols:thumb-up',
  opennessToExperience: 'mdi:head-question',
  enthusiasm: 'bi:person-arms-up',
  empathy: 'mdi:hand-heart',
  grit: 'fa6-solid:hand-fist',
  proactiveness: 'mdi:lightbulb-on',
  negativeExpressionOfEmotion: 'mdi:comment-minus',
};

const accuracySurvey = {
  veryUnsatisfied: 'mdi:emoticon-frown-outline',
  unsatisfied: 'mdi:emoticon-confused-outline',
  neutral: 'mdi:emoticon-neutral-outline',
  satisfied: 'mdi:emoticon-happy-outline',
  verySatisfied: 'mdi:emoticon-excited-outline',
};

const piccIcon = {
  actions,
  alertBanner,
  applicationIcons,
  assessmentCredits,
  candidateStatus,
  jobCredentials,
  jobStatus,
  menu,
  other,
  traits,
  transcription,
  psychometrics,
  accuracySurvey,
};

export default piccIcon;

import {
  ApplicationAssessmentResponse,
  AssessmentQuestion,
  AssessmentResponseType,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Button } from '@mui/material';

import { useEffect, useMemo, useState } from 'react';

import {
  TrackingEvent,
  useBoolean,
  useEventTracker,
  useToast,
  useTranscriber,
} from 'src//hooks';
import Iconify from 'src/components/iconify';
import { useApplicationFlowContext } from 'src/contexts';
import assessmentConfig from 'src/contexts/applicationFlowContext/config';

import AnswerControlBox from '../answer-control-box';
import QuestionLayout from '../question-layout';

import AnswerProgress from './answer-progress';
import TextFieldWithIcon from './text-field-with-icon';

export interface AnswerTextProps {
  onSave: (input: string, responseType: AssessmentResponseType) => Promise<void>;
  loading: boolean;
  loadingDelete: boolean;
  onClose: VoidFunction;
  currentAnswer?: ApplicationAssessmentResponse;
  onActive: VoidFunction;
  isActive: boolean;
  question: AssessmentQuestion & {
    main: string;
    lighter: string;
  } & { currentAnswer?: ApplicationAssessmentResponse | undefined };
}

export default function AnswerText(props: AnswerTextProps) {
  const {
    onSave,
    currentAnswer,
    loading,
    loadingDelete,
    onClose,
    onActive,
    isActive,
    question,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();
  const eventTracker = useEventTracker();
  const toast = useToast();
  const { MIN_WORD_COUNT, TARGET_WORD_COUNT } = assessmentConfig;

  const [textInput, setTextInput] = useState(currentAnswer ? currentAnswer.rawText : '');
  const transcriber = useTranscriber(textInput, setTextInput);

  const wordCount = useMemo(
    () => textInput
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .length,
    [textInput],
  );
  const [responseType, setResponseType] = useState(currentAnswer?.type ?? AssessmentResponseType.TEXT);

  const calledOnActive = useBoolean();
  const calledOnActivePreload = useBoolean();

  const isMinWordCountMet = !applicationFlowContext.isPreview ? wordCount >= MIN_WORD_COUNT : true;

  const calculateProgress = () => {
    if (wordCount >= TARGET_WORD_COUNT) {
      return 100;
    }
    return Math.floor((wordCount / TARGET_WORD_COUNT) * 100);
  };

  useEffect(() => {
    // cases on preloaded answer
    if (
      currentAnswer
      && currentAnswer?.rawText !== textInput
      && textInput !== ''
    ) {
      if (!calledOnActivePreload.value) {
        onActive();
        calledOnActivePreload.onTrue();
        setResponseType(currentAnswer.type);
      }
    }

    // cases on new answer
    if ((
      !currentAnswer
      || !currentAnswer.rawText
      || currentAnswer.rawText === ''
    ) && textInput !== ''
    ) {
      if (!calledOnActive.value) {
        onActive();
        calledOnActive.onTrue();
      }
    }
  }, [textInput, calledOnActive, onActive, question.currentAnswer, currentAnswer, calledOnActivePreload]);

  useEffect(() => {
    setTextInput(question.currentAnswer ? question.currentAnswer.rawText : '');
  }, [question.id, question.currentAnswer]);

  const handleTranscription = async () => {
    try {
      if (!transcriber.isTranscribing) {
        await transcriber.start();

        if (responseType !== AssessmentResponseType.TRANSCRIBED_TEXT) {
          setResponseType(AssessmentResponseType.TRANSCRIBED_TEXT);
        }
      } else {
        await transcriber.stop();
      }
    } catch (error) {
      console.error('Error transcribing:', error);
      toast.error('Failed to start transcription');
    }
  };

  const updateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(e.target.value);
    transcriber.setTranscription(e.target.value);
    transcriber.setPartialTranscription('');
  };

  const onSubmit = async () => {
    await transcriber.close();
    const event = responseType === AssessmentResponseType.TRANSCRIBED_TEXT
      ? TrackingEvent.UPLOAD_ASSESSMENT_ANSWER_AS_TRANSCRIBED_TEXT
      : TrackingEvent.UPLOAD_ASSESSMENT_ANSWER_AS_TEXT;
    eventTracker.trackEvent(event, {
      applicationId: currentAnswer?.applicationId,
      answerId: currentAnswer?.id,
    });
    await onSave(textInput, responseType);
    onActive();
  };

  return (
    <QuestionLayout
      currentQuestion={question}
      controlBox={(
        <AnswerControlBox
          loading={loading}
          onClose={() => {
            setTimeout(async () => {
              await transcriber.close();
              onClose();
            }, 300);
          }}
          onClick={onSubmit}
          saveButtonDisabled={loadingDelete}
          isMinWordCountMet={isMinWordCountMet}
          isActive={isActive}
        />
      )}
    >
      <Box display="flex" flexDirection="column" rowGap={1} sx={{ pt: 2 }}>
        <Box display="flex" flexDirection="column">
          <TextFieldWithIcon
            value={textInput}
            onChange={updateText}
            iconProps={transcriber.iconProps}
            onIconClick={handleTranscription}
            topComponent={<AnswerProgress progress={calculateProgress()} />}
            placeholder="Answer"
            minRows={8}
            disabled={transcriber.isTranscribing}
          />
          <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '100%', pt: 1 }}>
            <Box>
              <Button
                color="primary"
                startIcon={<Iconify icon="mdi:trash-can-outline" />}
                onClick={async () => {
                  setTextInput('');
                  await transcriber.close();
                }}
                disabled={textInput === '' || transcriber.isTranscribing || loading}
                sx={{
                  px: 1,
                  minWidth: 0,
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </QuestionLayout>
  );
}

import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import Image from 'src/components/image';
import { useApplicationFlowContext } from 'src/contexts';

import QuestionCard from '../partials/question-card';

export interface TabReviewProps {
  onBack: VoidFunction;
  onNext: VoidFunction;
  loading: boolean;
}

export default function TabReview(props: TabReviewProps) {
  const {
    onBack,
    onNext,
    loading,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();
  const isPreview = applicationFlowContext?.isPreview;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{
            pt: 3,
          }}
        >
          <Image
            height="120px"
            src="/assets/illustrations/candidate-assessment-submit.svg"
          />
        </Box>
        <QuestionCard title="Ready to submit your assessment?">
          <Typography>
            Thank you for taking the time to complete the TEQ Assessment.
            Once you click submit, your answers cannot be edited.
          </Typography>
        </QuestionCard>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        columnGap={1}
        rowGap={1}
        sx={{
          py: 2,
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={onNext}
        >
          {isPreview ? 'Close preview' : 'Submit'}
        </LoadingButton>
      </Box>
    </>
  );
}

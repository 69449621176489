import services, { TEQApplicationStatus } from '@piccadilly-cloud/connect-platform-services';

import { Button, Typography, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { useEffect, useState } from 'react';

import { RequestCreditsDialog } from 'src/components';
import { useSettingsContext } from 'src/components/settings';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useBoolean, useResponsive } from 'src/hooks';

import HeaderBanner from '../_common/header-banner';
import { HEADER, NAV } from '../config-layout';

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();
  const session = useSessionContext();
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const showBanner = useBoolean();
  const [unprocessedApplicationsCount, setUnprocessedApplicationsCount] = useState(0);
  const isRequestCreditsDialogOpen = useBoolean();

  useEffect(() => {
    (async () => {
      try {
        const res = await services.edge.teq.applications.getApplicationsCount(
          TEQApplicationStatus.SUBMITTED,
        )({ token: session.token });
        setUnprocessedApplicationsCount(res);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [session.token]);

  useEffect(() => {
    if (!session.dynamicVendor || !session.dynamicVendor.parentVendorId) {
      return;
    }

    const hasRemainingAssessments = session.dynamicVendor.assessments.remaining > 0;
    if (!hasRemainingAssessments) {
      showBanner.onTrue();
    }
  }, [session.dynamicVendor, session.account.accountRole, showBanner]);

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';

  const renderBanner = () => {
    const renderSubtitle = () => !!unprocessedApplicationsCount && (
      <Box sx={{ pb: 2 }}>
        <Typography
          variant="body2"
          color={theme.palette.warning.darker}
          fontWeight={theme.typography.fontWeightRegular}
        >
          You have candidates on hold that might be a good fit.
        </Typography>
      </Box>
    );

    return (
      <Box pb={theme.spacing(3)}>
        <HeaderBanner sx={{ height: '200px' }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap={1}
          >
            <Box
              component="img"
              src="/assets/credit-warn.png"
              alt="assessment limit"
              width={24}
              height={24}
            />
            <Typography
              variant="body2"
              color={theme.palette.warning.darker}
              fontWeight={theme.typography.fontWeightBold}
            >
              You’ve reached your assessment limit
            </Typography>
            {renderSubtitle()}
            <Button
              variant="contained"
              onClick={isRequestCreditsDialogOpen.onTrue}
              sx={{
                backgroundColor: theme.palette.warning.main,
                '&:hover': {
                  backgroundColor: theme.palette.warning.dark,
                },
              }}
            >
              Request assessments
            </Button>
          </Box>
        </HeaderBanner>
      </Box>
    );
  };

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {showBanner.value && renderBanner()}
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          // px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {showBanner.value && renderBanner()}
      <Box id="header-banner" />
      {children}
      <RequestCreditsDialog
        open={isRequestCreditsDialogOpen.value}
        emit={{
          close: isRequestCreditsDialogOpen.onFalse,
        }}
      />
    </Box>
  );
}

import services, {
  BillingType,
  CreateVendorRequest,
  DynamicVendorCreateRequest,
  DynamicVendorPublic,
  UserAccountInvitationResult,
} from '@piccadilly-cloud/connect-platform-services';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Alert as MuiAlert,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import FormProvider, { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { getAppHostUrl } from 'src/contexts/session/utils';
import ctrl from 'src/ctrl';
import { useToast } from 'src/hooks';
import piccIcon from 'src/utils/config/icon';
import { Invitee, initInvitee } from 'src/utils/initializers/appConfig';

interface InvitationForm {
  invitees: Invitee[];
}

interface VendorInvitationFormProps {
  initialInvitee?: Invitee;
  data: DynamicVendorPublic;
  isFirstInvite?: boolean;
  onRefresh: VoidFunction;
  onUpdateRemaining: (assigned: number) => void;
  remainingAssessments: number;
}

export default function VendorInvitationForm(props: VendorInvitationFormProps) {
  const {
    initialInvitee,
    data,
    isFirstInvite,
    onRefresh,
    onUpdateRemaining,
    remainingAssessments,
  } = props;
  const session = useSessionContext();
  const theme = useTheme();
  const toast = useToast();

  const [results, setResults] = useState<UserAccountInvitationResult[]>([]);
  const [serverErr, setServerErr] = useState('');
  const viewOnly = !!initialInvitee;

  const InvitationSchema = Yup.object().shape({
    invitees: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
          .matches(ctrl.userAccount.emailRegex, { message: 'Must enter a valid email address' })
          .required('Email is required'),
        url: Yup.string().required('Company URL is required'),
        companyName: Yup.string().required('Company Name is required'),
        assessments: Yup.number()
          .min(1, 'A minimum of 1 assessment must be assigned')
          .required('Assessments are required'),
        useDemographics: Yup.bool().required(),
        useDemographicsExtended: Yup.bool().required(),
        useGenericProfiles: Yup.bool().required(),
        usePrequalifyCandidates: Yup.bool().required(),
        useAssessmentPurchases: Yup.bool().required(),
      }),
    ).required(),
  });

  const defaultValues: InvitationForm = useMemo(() => ({
    invitees: [initialInvitee ?? initInvitee()],
  }), [
    initialInvitee,
  ]);

  const methods = useForm<InvitationForm>({
    resolver: yupResolver(InvitationSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (initialInvitee) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, initialInvitee]);

  const inviteesValues = watch('invitees');

  // Listen for changes in invitees' assessments and update remaining assessments
  useEffect(() => {
    const totalAssessmentsAssigned = inviteesValues.reduce((acc, curr) => acc + (curr.assessments || 0), 0);
    onUpdateRemaining(totalAssessmentsAssigned);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteesValues]);

  const onSubmit = useCallback(
    async (formData: InvitationForm) => {
      try {
        setServerErr('');

        const numToSend = formData.invitees.map((d) => d.assessments).reduce((a, b) => a + b);
        if (numToSend > data.assessments.remaining) {
          setServerErr('Not enough available assessments to send invitations.');
          return;
        }

        const invites: DynamicVendorCreateRequest[] = formData.invitees.map((d) => ({
          appHost: getAppHostUrl(d.url),
          billingType: BillingType.PREPAID,
          parentVendorId: session.appConfig.vendor,
          companyName: d.companyName,
          primaryContact: {
            firstName: d.firstName,
            lastName: d.lastName,
            email: d.email,
          },
          assessments: d.assessments,
          canAssignAssessments: false,
          useDemographicCollection: d.useDemographics,
          useDemographicCollectionExtended: d.useDemographicsExtended,
          useGenericProfiles: d.useGenericProfiles,
          jobListingCreditsEnabled: false,
          usePrequalifyCandidates: d.usePrequalifyCandidates,
          useAssessmentPurchases: d.useAssessmentPurchases,
          useDemoMode: false,
          createWorkspace: true,
          createWorkspaceRequest: {
            id: '',
            vendor: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            deleted: false,
            data: {
              companyName: d.companyName,
              companyLocation: '',
              showCompanyLocation: false,
              companyWebsite: '',
              showCompanyWebsite: false,
              companyAbout: '',
              showCompanyAbout: false,
              companySize: '',
              showCompanySize: false,
              companyLogo: '',
              companyTag: '',
              headerImage: '',
              primaryColor: '',
              secondaryColor: '',
              sections: {},
              sectionOrderGroup1: [],
              sectionOrderGroup2: [],
              sectionOrderGroup3: [],
            },
            departments: [],
            useGenericProfiles: d.useGenericProfiles,
          },
        }));

        const request: CreateVendorRequest = {
          parentVendorId: session.appConfig.vendor,
          invitedBy: session.account.email,
          invitedByName: `${session.account.personalInfo.firstName} ${session.account.personalInfo.lastName}`,
          requests: invites,
        };

        const result = await services.edge.manage.createVendors(request)({
          token: session.token,
          vendor: session.appConfig.vendor,
        });
        setResults([...result.results]);
        if (result.results.length !== 0 && result.results[0].sent) {
          reset();
        }
      } catch (err) {
        toast.error('Something went wrong! Please try again.');
        console.error(err);
      } finally {
        onRefresh();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      session.appConfig.vendor,
      session.account.email,
      session.account.personalInfo.firstName,
      session.account.personalInfo.lastName,
      session.token,
    ],
  );

  const handleAssessmentChange = (index: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as number;
    const nextInvitees = [...inviteesValues];
    nextInvitees[index].assessments = newValue;
    setValue('invitees', nextInvitees);
    const totalAssessmentsAssigned = nextInvitees.reduce((acc, curr) => acc + (curr.assessments || 0), 0);
    onUpdateRemaining(totalAssessmentsAssigned);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, (e) => console.error(e))}>
      {results.length !== 0 && (
        <Box display="flex" flexDirection="column" rowGap={2} sx={{ pb: 3 }}>
          {results.map((i) => (
            <MuiAlert
              key={`result-${i.id}`}
              variant="outlined"
              severity={i.sent ? 'success' : 'error'}
              action={(
                <IconButton
                  size="small"
                  color={i.sent ? 'success' : 'error'}
                  onClick={() => {
                    const nextResults = results.filter((j) => i.id !== j.id);
                    setResults([...nextResults]);
                  }}
                >
                  <Iconify icon="ph:x" />
                </IconButton>
              )}
            >
              {i.sent
                ? (<>Successfully sent invitation to {i.data.email}</>)
                : (<>Failed to send to {i.data.email}: <b>{i.message}</b></>)}
            </MuiAlert>
          ))}
        </Box>
      )}
      {serverErr !== '' && (
        <MuiAlert
          variant="outlined"
          severity="error"
        >
          {serverErr}
        </MuiAlert>
      )}

      <Stack spacing={2}>
        {inviteesValues.map((_, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Box
              sx={{
                p: 2,
                borderStyle: isFirstInvite ? 'none' : 'solid',
                borderRadius: 1,
                borderColor: index === 0 ? theme.palette.grey[200] : theme.palette.grey[100],
                flexGrow: 1,
              }}
            >
              {!initialInvitee && index === 0 && (
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6" fontWeight={600}>
                    Vendor
                  </Typography>
                  <Typography variant="body2">
                    This is creating the workspace for the new vendor. We recommend this be the same as the company
                    name without spaces.
                  </Typography>
                </Box>
              )}

              <Box display="flex" flexDirection="row" alignItems="center" columnGap={3} sx={{ pt: 1, pb: 2 }}>
                <Box sx={{ width: '30%' }}>
                  <RHFTextField
                    size="small"
                    name={`invitees.${index}.companyName`}
                    label="Company name*"
                    disabled={viewOnly}
                  />
                </Box>
                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <RHFTextField
                    size="small"
                    name={`invitees.${index}.url`}
                    label="URL*"
                    disabled={viewOnly}
                  />
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      backgroundColor: theme.palette.grey[50],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="body2" fontWeight={500}>
                      .teqconnect.com
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: '20%' }}>
                  <RHFTextField
                    size="small"
                    name={`invitees.${index}.assessments`}
                    label="Assessments*"
                    type="number"
                    disabled={viewOnly}
                    onChange={handleAssessmentChange(index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Box>
              {index === 0 && (
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h6" fontWeight={600}>
                    Primary contact
                  </Typography>
                  {!initialInvitee && (
                    <Typography variant="body2">
                      This will be the workspace admin for the new vendor.
                    </Typography>
                  )}
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="row"
                columnGap={3}
                sx={{ pt: 1 }}
              >
                <RHFTextField
                  size="small"
                  name={`invitees.${index}.firstName`}
                  label="First name*"
                  disabled={viewOnly}
                />
                <RHFTextField
                  size="small"
                  name={`invitees.${index}.lastName`}
                  label="Last name*"
                  disabled={viewOnly}
                />
                <RHFTextField
                  size="small"
                  name={`invitees.${index}.email`}
                  label="Email*"
                  disabled={viewOnly}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                columnGap={3}
                sx={{ pt: 1 }}
              >
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <RHFCheckbox
                    name={`invitees.${index}.useGenericProfiles`}
                    label="Use Generic Profiles"
                    disabled={viewOnly}
                  />
                  <RHFCheckbox
                    name={`invitees.${index}.useDemographics`}
                    label="Collect Demographic Data"
                    disabled={viewOnly}
                  />
                </Box>
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <RHFCheckbox
                    name={`invitees.${index}.useDemographicsExtended`}
                    label="Collect Extended Demographic Data"
                    disabled={viewOnly}
                  />
                  <RHFCheckbox
                    name={`invitees.${index}.usePrequalifyCandidates`}
                    label="Enable Candidate Pre-Qualification"
                    disabled={viewOnly}
                  />
                </Box>
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <RHFCheckbox
                    name={`invitees.${index}.useAssessmentPurchases`}
                    label="Allow Assessment Purchases"
                    disabled={viewOnly}
                  />
                </Box>
              </Box>
            </Box>
            {inviteesValues.length !== 1 && index !== 0 && (
              <IconButton
                onClick={() => {
                  const nextInvitees = [...getValues().invitees];
                  nextInvitees.splice(index, 1);
                  setValue('invitees', nextInvitees);
                }}
              >
                <Iconify icon="mdi:close-circle" />
              </IconButton>
            )}
          </Box>
        ))}
      </Stack>
      {!isFirstInvite && remainingAssessments >= 30 && !viewOnly && (
        <Button
          color="primary"
          startIcon={<Iconify icon={piccIcon.actions.add} />}
          onClick={() => {
            const nextInvitees = [
              ...getValues().invitees,
              {
                firstName: '',
                lastName: '',
                email: '',
                url: '',
                companyName: '',
                assessments: 30,
                useDemographics: false,
                useDemographicsExtended: false,
                useGenericProfiles: false,
                usePrequalifyCandidates: false,
                useAssessmentPurchases: false,
              },
            ];
            setValue('invitees', nextInvitees);
          }}
        >
          Add
        </Button>
      )}
      {!viewOnly && (
        <Box sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={isSubmitting}
            startIcon={<Iconify icon={piccIcon.actions.send} />}
            fullWidth
          >
            Invite
          </LoadingButton>
        </Box>
      )}
    </FormProvider>
  );
}

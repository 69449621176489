import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/session/guards/auth-guard';
import InternalLayout from 'src/layouts/internal';

import ExternalRedirect from '../components/external-redirect';

const Dashboard = lazy(() => import('src/pages/dashboard'));
const Profile = lazy(() => import('src/pages/user-account/profile'));
const AppSettings = lazy(() => import('src/pages/app/app-settings'));
const InviteUsersPage = lazy(() => import('src/pages/user-management/invite-users'));
const InviteUsersResultsPage = lazy(() => import('src/pages/user-management/invite-users-results'));
const InviteUsersListPage = lazy(() => import('src/pages/user-management/invite-users-list'));
const AllUsers = lazy(() => import('src/pages/user-management/all-users'));
const ViewUser = lazy(() => import('src/pages/user-management/single-user'));

const ResourcesSupportPage = lazy(() => import('src/pages/recruiter/resources/support-page'));

const ExperimentalAudioUpload = lazy(() => import('src/pages/admin/experimental/audio-upload-page'));
const ExperimentalTeqProcessing = lazy(() => import('src/pages/admin/experimental/teq-processing-page'));

export const adminRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <InternalLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </InternalLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <Dashboard />, index: true },
      { path: 'profile', element: <Profile />, index: true },
      { path: 'app-settings', element: <AppSettings />, index: true },
      { path: 'user-management/invite-users/invite/results', element: <InviteUsersResultsPage />, index: true },
      { path: 'user-management/invite-users/invite', element: <InviteUsersPage />, index: true },
      { path: 'user-management/pending-users', element: <InviteUsersListPage />, index: true },
      { path: 'user-management/all-users', element: <AllUsers />, index: true },
      { path: 'user-management/all-users/:userId', element: <ViewUser />, index: true },

      {
        path: 'admin/resources/knowledge-base',
        element: <ExternalRedirect href={process.env.REACT_APP_ZENDESK_HC_URL!} />,
      },
      { path: 'admin/resources/support', element: <ResourcesSupportPage /> },

      { path: 'admin/experimental/audio-upload', element: <ExperimentalAudioUpload /> },
      { path: 'admin/experimental/teq-processing', element: <ExperimentalTeqProcessing /> },
    ],
  },
];

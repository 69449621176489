import { useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router';

import { trackPageViewSegment } from 'src/ctrl/segment';

export interface PageGuardProps {
  children: React.ReactNode;
}

export default function PageGuard(props: PageGuardProps) {
  const { children } = props;
  const location = useLocation();
  const prevLocation = useRef<Location>();

  useEffect(() => {
    trackPageViewSegment(location, prevLocation.current);
    prevLocation.current = location;
  }, [location]);

  return <>{children}</>;
}

import { Box, Container, useTheme } from '@mui/material';

import { ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';

import HeaderBanner, { HeaderBannerProps } from 'src/layouts/v2/_common/header-banner';

export const useHeaderBanner = () => {
  const theme = useTheme();
  const headerBannerElement = document.getElementById('header-banner');

  const render = useCallback((content: ReactNode, props?: HeaderBannerProps): ReactNode => {
    if (!headerBannerElement) {
      return null;
    }

    return createPortal(
      <Box pb={theme.spacing(3)}>

        <HeaderBanner {...props}>
          <Container maxWidth="xl">
            {content}
          </Container>
        </HeaderBanner>
      </Box>,
      headerBannerElement,
    );
  }, [headerBannerElement, theme]);

  return {
    render,
  };
};

import { Paper, PaperProps, useTheme } from '@mui/material';

import teqScoreConfigV2 from 'src/components-piccadilly/teq-score/v2/config';

export interface HeaderBannerProps extends PaperProps {
  children?: React.ReactNode;
  color?: string;
  height?: number | string;
  disableElevation?: boolean;
  isSticky?: boolean;
}

export default function HeaderBanner(props: HeaderBannerProps) {
  const { children, color, disableElevation, isSticky, sx } = props;
  const theme = useTheme();

  return (
    <Paper
      elevation={disableElevation ? 0 : 3}
      sx={{
        backgroundColor: color || teqScoreConfigV2.palette[2].light,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        ...(isSticky && {
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar + 2,
        }),
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

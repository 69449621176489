import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Paper,
  PaperProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { debounce } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useSessionContext } from 'src/contexts/session/useSessionContext';
import addressAutocomplete from 'src/ctrl/addressAutocomplete';

import Iconify from '../iconify';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  countryCode?: string;
  citiesOnly?: boolean;
  disableDefaultSubmit?: boolean;
  size?: 'small' | 'medium';
  useIcon?: boolean;
};

export default function RHFAddress({
  name,
  label,
  placeholder,
  helperText,
  countryCode,
  citiesOnly,
  disableDefaultSubmit,
  size,
  useIcon,
}: Props) {
  const { control, setValue, watch } = useFormContext();
  const session = useSessionContext();
  const optionSelected = useRef(false);
  const settings = useSettingsContext();
  const theme = useTheme();

  // Set a session token for Maps API when component mounts
  const requestToken = useMemo(() => uuidv4(), []);

  const value = watch(name) ?? '';
  const suggestions = watch('suggestions') ?? [];

  const fetchSuggestions = useMemo(() => debounce((input: string) => {
    const trimmedInput = input.trim();
    const uniqueInput = trimmedInput !== '' && trimmedInput !== value;
    if (uniqueInput && !optionSelected.current) {
      setValue('suggestions', []);
      addressAutocomplete.getAutocompleteSuggestions(
        session,
        input,
        requestToken,
        citiesOnly ?? false,
        countryCode ?? '',
      )
        .then((response) => {
          setValue('suggestions', response.predictions);
        });
    } else {
      setValue('suggestions', []);
    }

    optionSelected.current = false;
  }, 400), [citiesOnly, countryCode, requestToken, session, setValue, value]);

  const fetchDetails = (id: string) => addressAutocomplete.getAddressDetails(
    session,
    id,
    requestToken,
  )
    .then((response) => {
      optionSelected.current = true;
      setValue(name, response.formattedAddress, { shouldValidate: true });
    });

  const customPaperComponent = ({ children, ...paperProps }: PaperProps) => {
    let imgSrc = '/assets/icons/google/google_on_white.png';
    if (settings.themeMode === 'dark') {
      imgSrc = '/assets/icons/google/google_on_non_white.png';
    }

    const googleLogo = (
      <img
        src={imgSrc}
        alt="Google Logo"
        style={{ maxWidth: 50 }}
      />
    );

    const poweredByText = (
      <Box display="flex" sx={{ width: '100%', alignItems: 'flex-end' }}>
        <Typography variant="caption" sx={{ py: 1, px: 2, textAlign: 'right', width: '100%' }}>
          Powered By
          {' '}
          {googleLogo}
        </Typography>
      </Box>
    );

    return (
      <Paper {...paperProps} style={{ ...paperProps.style }}>
        {children}
        {suggestions.length > 0 && poweredByText}
      </Paper>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          freeSolo
          fullWidth
          size={size}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.fullAddress}
          options={suggestions}
          autoComplete
          noOptionsText="No results"
          onInputChange={(_, newInputValue) => {
            fetchSuggestions(newInputValue);
          }}
          onChange={(_, newValue) => {
            if (newValue && typeof newValue === 'object') {
              fetchDetails(newValue.id);
            } else {
              setValue(name, newValue, { shouldValidate: true });
            }
          }}
          PaperComponent={customPaperComponent}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.value ? label : undefined}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              InputProps={{
                ...params.InputProps,
                startAdornment: useIcon
                  ? (
                    <InputAdornment position="end">
                      <Iconify icon="mdi:map-marker" sx={{ color: theme.palette.grey[300] }} />
                    </InputAdornment>
                  ) : undefined,
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && disableDefaultSubmit) {
                  e.preventDefault();
                }
              }}
              SelectProps={{
                sx: {
                  color: theme.palette.grey[300],
                  textTransform: 'capitalize',
                  borderRadius: theme.shape.borderRadius,
                  '& .MuiSelect-icon': {
                    color: theme.palette.grey[300],
                  },
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }} />
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: 'regular' }}
                  >
                    {option.mainText || option}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )}
        />
      )}
    />
  );
}

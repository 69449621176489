import { lazy } from 'react';
import { RouteObject } from 'react-router';

const Billing = lazy(() => import('src/pages/billing'));

export const billingRoutes: RouteObject[] = [
  {
    path: 'billing',
    element: <Billing />,
  },
];

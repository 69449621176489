import { ListingPlanInfo } from '@piccadilly-cloud/connect-platform-services';

import { Chip, useTheme } from '@mui/material';

import Iconify from '../iconify';

export interface PlanChipProps {
  planInfo?: ListingPlanInfo;
}

export default function PlanChip(props: PlanChipProps) {
  const { planInfo } = props;
  const theme = useTheme();

  if (!planInfo) {
    return null;
  }

  const { icon, name } = planInfo;
  const iconElement = icon
    ? (
      <Iconify
        icon={icon}
        width={16}
        height={16}
      />
    ) : undefined;

  return (
    <Chip
      label={name}
      variant="outlined"
      color="primary"
      icon={iconElement}
      sx={{
        borderRadius: '20px',
        padding: '6px 12px',
        gap: 1,
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.light,
        width: 'fit-content',
        '& .MuiChip-icon': {
          padding: 0,
          margin: 0,
        },
        '& .MuiChip-label': {
          padding: 0,
          fontSize: theme.typography.fontSize,
          fontWeight: theme.typography.fontWeightSemiBold,
          textTransform: 'uppercase',
          letterSpacing: '1px',
        },
      }}
    />
  );
}

import { Typography } from '@mui/material';

import ContactUs from '../links/ContactUs';

import CustomDialog from './custom-dialog';

export interface PlansComingSoonDialogEmits {
  close?: () => void;
}

export interface PlansComingSoonDialogProps {
  open: boolean;
  emit?: PlansComingSoonDialogEmits;
}

export default function PlansComingSoonDialog(props: PlansComingSoonDialogProps) {
  const { open, emit } = props;
  const contactUs = <ContactUs />;

  return (
    <CustomDialog
      open={open}
      hideCancel
      hideConfirm
      emit={emit}
      title="Plans coming soon"
    >
      <Typography width={570}>
        Please {contactUs} for pricing.
      </Typography>
    </CustomDialog>
  );
}

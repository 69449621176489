import { CandidateProfile, UniformProfileDefinition } from '@piccadilly-cloud/connect-platform-services';

import {
  TabAssessmentTypeForm,
  TabAvailabilityForm,
  TabCredentialsForm,
  TabEducationForm,
  TabEligibilityForm,
  TabExperienceForm,
  TabLocationForm,
} from './form-types';

const tabExperience = (profile: CandidateProfile): TabExperienceForm => ({
  experienceLevel: profile.experience.experienceLevel || 0,
});

const tabLocation = (profile: CandidateProfile): TabLocationForm => ({
  location: profile.location.location || '',
  locationType: profile.location.locationType || [],
  listingRequiresRelocation_28: profile.location.listingRequiresRelocation_28 || 0,
  scheduleWillingToTravel_24: profile.location.scheduleWillingToTravel_24 || 1,
});

const tabAvailability = (profile: CandidateProfile, jobProfile: UniformProfileDefinition): TabAvailabilityForm => {
  const base: TabAvailabilityForm = {
    employmentType: profile.availability.employmentType || [],
    positionType: profile.availability.positionType || [],
    profileAttributes: profile.availability.profileAttributes || {},
  };
  jobProfile.availability.fields.forEach((field) => {
    if (!base.profileAttributes[field.id]) {
      base.profileAttributes[field.id] = 3;
    }
  });
  return base;
};

const tabEligibility = (profile: CandidateProfile, jobProfile: UniformProfileDefinition): TabEligibilityForm => {
  const base: TabEligibilityForm = {
    listingAgeRequirement_13: profile.eligibility.listingAgeRequirement_13
      ? profile.eligibility.listingAgeRequirement_13 === 5
      : true,
    authorizedToWork_12: profile.eligibility.authorizedToWork_12
      ? profile.eligibility.authorizedToWork_12 === 5
      : true,
    profileAttributes: {},
  };
  jobProfile.eligibility.fields.forEach((attr) => {
    base.profileAttributes[attr.id] = true;
  });
  if (profile.eligibility.profileAttributes) {
    Object.keys(profile.eligibility.profileAttributes).forEach((attrKey) => {
      const attrValue = profile.eligibility.profileAttributes[attrKey];
      base.profileAttributes[attrKey] = attrValue === 5;
    });
  }
  return base;
};

const tabEducation = (profile: CandidateProfile): TabEducationForm => ({
  educationLevel: profile.education.educationLevel || '',
  additionalEducation: profile.education.additionalEducation || [],
});

const tabCredentials = (profile: CandidateProfile): TabCredentialsForm => ({
  certificates: profile.credentials.certificates || {},
  notes: profile.credentials.notes || '',
  canProvideCertificates: profile.credentials.canProvideCertificates || false,
});

const tabAssessmentType = (profile: CandidateProfile): TabAssessmentTypeForm => ({
  responseType: profile.assessment.responseType,
});

const formDefaults = {
  tabExperience,
  tabLocation,
  tabAvailability,
  tabEligibility,
  tabEducation,
  tabCredentials,
  tabAssessmentType,
};

export default formDefaults;

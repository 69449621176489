import { Box, Typography, useTheme } from '@mui/material';

import { useCallback, useEffect } from 'react';

import { useApplicationFlowContext } from 'src/contexts';
import { CandidateApplicationWizard } from 'src/views/candidate/application';

import CustomDialog from './custom-dialog';

export interface CandidateApplicationPreviewDialogEmits {
  close?: () => void;
}

export interface CandidateApplicationPreviewDialogProps {
  open: boolean;
  jobListingName: string;
  companyName: string;
  emit?: CandidateApplicationPreviewDialogEmits;
  isPreview?: boolean;
}

export default function CandidateApplicationPreviewDialog(
  props: CandidateApplicationPreviewDialogProps,
) {
  const { open, jobListingName, companyName, emit } = props;
  const { dispatch } = useApplicationFlowContext();

  const theme = useTheme();

  const initPreviewFlow = useCallback(() => {
    dispatch['applicationFlow/setIsPreview']({
      isPreview: true,
    });
    dispatch['applicationFlow/setListingName']({
      listingName: jobListingName,
    });
    dispatch['applicationFlow/setListingCompanyName']({
      companyName,
    });
  }, [dispatch, jobListingName, companyName]);

  useEffect(() => {
    initPreviewFlow();
  }, [dispatch, initPreviewFlow]);

  const handleClose = () => {
    dispatch['applicationFlow/resetState']({});
    initPreviewFlow();
    emit?.close?.();
  };

  const title = (
    <Box>
      <Typography variant="h4">
        TEQ Assessment [PREVIEW ONLY]
      </Typography>
      <Typography>
        Enter answers to demo or click Next to review. Any answers clear when you exit the assessment.
      </Typography>
    </Box>
  );

  return (
    <CustomDialog
      open={open}
      title={title}
      hideCancel
      hideConfirm
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '90%',
          height: '90%',
        },
      }}
      contentSX={{
        marginTop: '32px',
        height: '90%',
        backgroundColor: theme.palette.grey[50],
      }}
      emit={{
        close: handleClose,
      }}
    >
      <CandidateApplicationWizard
        emit={{
          close: handleClose,
        }}
      />
    </CustomDialog>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
import services, { BillingType } from '@piccadilly-cloud/connect-platform-services';

import {
  ReactNode,
  Reducer,
  createContext,
  useEffect,
  useReducer,
} from 'react';

import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';

import { PurchaseFlowAction, PurchaseFlowContextDispatch, PurchaseFlowDispatch, PurchaseFlowState } from './model';

type S = PurchaseFlowState;

const initState = (): S => ({
  address: undefined,
  coupons: [],
  dispatch: purchaseFlowDispatch(() => { }),
  listing: undefined,
  numAssessments: 0,
  paymentInfo: undefined,
  plans: [],
  receipt: undefined,
  selectedPlan: undefined,
  transaction: undefined,
});

const purchaseFlowMutations: ContextMutations<S, PurchaseFlowAction> = {
  'purchaseFlow/INIT_STATE': (state, { listing, plans, selectedPlan }) => ({
    ...initState(),
    dispatch: state.dispatch,
    listing,
    plans,
    selectedPlan,
    numAssessments: selectedPlan?.bundleSize ?? 0,
  }),

  'purchaseFlow/SET_ADDRESS': (state, { address }) => ({
    ...state,
    address,
  }),

  'purchaseFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'purchaseFlow/SET_LISTING': (state, { listing }) => ({
    ...state,
    listing,
  }),

  'purchaseFlow/SET_NUM_ASSESSMENTS': (state, { numAssessments }) => ({
    ...state,
    numAssessments,
  }),

  'purchaseFlow/SET_PAYMENT_INFO': (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
  }),

  'purchaseFlow/SET_RECEIPT': (state, { receipt }) => ({
    ...state,
    receipt,
  }),

  'purchaseFlow/SET_SELECTED_PLAN': (state, { selectedPlan }) => ({
    ...state,
    selectedPlan,
    numAssessments: selectedPlan?.bundleSize ?? 0,
  }),

  'purchaseFlow/SET_TRANSACTION': (state, { transaction }) => ({
    ...state,
    transaction,
  }),

  'purchaseFlow/SET_COUPONS': (state, { coupons }) => ({
    ...state,
    coupons,
  }),
};

const reducer = (
  state: S,
  action: PurchaseFlowAction,
): S => purchaseFlowMutations[action.type](state, action.payload as any) ?? state;

const purchaseFlowDispatch: PurchaseFlowContextDispatch = (dispatch): PurchaseFlowDispatch => ({
  'purchaseFlow/confirmTransaction': async (payload) => {
    try {
      const {
        listingId,
        planId,
        session,
        transactionId,
      } = payload;
      const {
        receipt,
        transaction,
      } = await services.edge.billing.transaction.confirm({
        listingId,
        planId,
        transactionId,
      })({ token: session.token });
      if (!transaction) {
        throw new Error('Transaction or receipt not found on confirmation');
      }

      dispatch({
        type: 'purchaseFlow/SET_TRANSACTION',
        payload: {
          transaction,
        },
      });

      if (receipt) {
        dispatch({
          type: 'purchaseFlow/SET_RECEIPT',
          payload: {
            receipt,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/createTransaction': async (payload) => {
    try {
      const {
        coupons,
        listingId,
        numAssessments,
        plan,
        session,
        workspaceId,
      } = payload;
      const { transaction } = await services.edge.billing.transaction.create({
        coupons,
        createdByEmail: session.account.email,
        createdByFirstName: session.account.personalInfo.firstName,
        createdByLastName: session.account.personalInfo.lastName,
        listingId,
        numAssessments,
        planId: plan.id,
        workspaceId,
      })({ token: session.token });
      if (!transaction) {
        throw new Error('Transaction not found on creation');
      }

      dispatch({
        type: 'purchaseFlow/SET_TRANSACTION',
        payload: {
          transaction,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/initPurchaseFlow': async (payload) => {
    try {
      const { session, listingVanityPath } = payload;
      if (!listingVanityPath) {
        return;
      }

      const [plans, listing] = await Promise.all([
        services.edge.billing.plan.getPlanByBillingType(BillingType.PREPAID)({
          token: session.token,
        }),
        services.edge.job.listing.getPublicListing(listingVanityPath)({
          token: session.token,
        }),
      ]);
      const filteredPlans = listing?.planInfo?.id
        ? plans.filter((plan) => plan.id === listing.planInfo?.id)
        : plans;
      dispatch({
        type: 'purchaseFlow/INIT_STATE',
        payload: {
          listing,
          plans: filteredPlans,
          selectedPlan: filteredPlans[0],
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/setAddress': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_ADDRESS', payload });
  },

  'purchaseFlow/setNumAssessments': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_NUM_ASSESSMENTS', payload });
  },

  'purchaseFlow/setPaymentInfo': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_PAYMENT_INFO', payload });
  },

  'purchaseFlow/setSelectedPlan': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_SELECTED_PLAN', payload });
  },

  'purchaseFlow/updateTransactionAmount': async (payload) => {
    try {
      const {
        coupons,
        listingId,
        numAssessments,
        plan,
        session,
        transaction: { id: transactionId },
      } = payload;
      const { transaction } = await services.edge.billing.transaction.updateAmount({
        listingId,
        numAssessments,
        planId: plan.id,
        transactionId,
        coupons,
      })({ token: session.token });
      if (!transaction) {
        throw new Error('Transaction not found on update');
      }

      dispatch({
        type: 'purchaseFlow/SET_TRANSACTION',
        payload: {
          transaction,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/setCoupons': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_COUPONS', payload });
  },
});

export interface PurchaseFlowProviderProps {
  children: ReactNode;
}

export const PurchaseFlowContext = createContext<S | null>(null);
export function PurchaseFlowProvider({ children }: PurchaseFlowProviderProps) {
  const [state, dispatch] = useReducer<Reducer<S, PurchaseFlowAction>>(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  useEffect(() => {
    dispatch({
      type: 'purchaseFlow/SET_DISPATCH',
      payload: { dispatch: purchaseFlowDispatch(dispatch) },
    });
  }, [dispatch]);

  return (
    <PurchaseFlowContext.Provider value={state}>
      {children}
    </PurchaseFlowContext.Provider>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  PaperOwnProps,
  SxProps,
  Theme,
  styled,
} from '@mui/material';

import { ReactNode } from 'react';

import Iconify from 'src/components/iconify';
import piccIcon from 'src/utils/config/icon';

export interface CustomDialogEmits {
  close?: () => void;
  confirm?: () => void;
}
export interface CustomDialogProps extends Omit<DialogProps, 'onClose' | 'onConfirm' | 'title'> {
  cancelText?: string;
  cancelVariant?: boolean;
  children?: ReactNode;
  confirmDisabled?: boolean;
  confirmEndIcon?: string;
  confirmStartIcon?: string;
  confirmText?: string;
  contentSX?: SxProps<Theme>;
  emit?: CustomDialogEmits;
  hideCancel?: boolean;
  hideConfirm?: boolean;
  hideTitle?: boolean;
  isError?: boolean;
  loading?: boolean;
  open: boolean;
  title?: string | ReactNode;
}

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontFamily: 'Outfit, sans-serif',
  fontSize: 'var(--size-L, 24px)',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '116.7%',
  letterSpacing: '0.15px',
  color: 'var(--Grey-900, #383E44)',
  fontFeatureSettings: "'liga' off, 'clig' off",
  '&.MuiDialogTitle-root': {
    padding: '32px 32px 0px 32px',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ sx }) => ({
  '&.MuiDialogContent-root': {
    padding: '32px',
    ...sx,
    minWidth: '450px',
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  '&.MuiDialogActions-root': {
    padding: '0px 32px 32px 32px',
  },
}));

export default function CustomDialog(props: CustomDialogProps) {
  const {
    cancelText = 'Cancel',
    children,
    confirmDisabled,
    confirmEndIcon,
    confirmStartIcon,
    confirmText = 'Confirm',
    cancelVariant,
    contentSX,
    emit,
    fullScreen,
    fullWidth,
    hideCancel,
    hideConfirm,
    hideTitle,
    isError,
    loading,
    open,
    sx,
    title,
    ...dialogProps
  } = props;

  const paperProps: PaperOwnProps = {
    sx: {
      borderRadius: '20px',
      ...(sx && ('& .MuiDialog-paper' in sx) && { '& .MuiDialog-paper': sx['& .MuiDialog-paper'] }),
    },
  };

  const renderTitle = (): ReactNode => {
    if (hideTitle) {
      return null;
    }

    return (
      <StyledDialogTitle variant="h5">
        <span>{title}</span>
        <IconButton onClick={emit?.close}>
          <Iconify icon={piccIcon.other.close} width="24px" />
        </IconButton>
      </StyledDialogTitle>
    );
  };

  const hideActions = hideCancel && hideConfirm;
  const renderActions = (): ReactNode => {
    if (hideActions) {
      return null;
    }

    return (
      <StyledDialogActions>
        {!hideCancel && (
          <Button
            variant={cancelVariant ? 'text' : 'outlined'}
            disabled={loading}
            onClick={emit?.close}
            color={isError ? 'error' : 'primary'}
          >
            {cancelText}
          </Button>
        )}
        {!hideConfirm && (
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={confirmDisabled}
            onClick={emit?.confirm}
            color={isError ? 'error' : 'primary'}
            startIcon={confirmStartIcon && <Iconify icon={confirmStartIcon} />}
            endIcon={confirmEndIcon && <Iconify icon={confirmEndIcon} />}
          >
            {confirmText}
          </LoadingButton>
        )}
      </StyledDialogActions>
    );
  };

  const getContentPadding = () => {
    if (hideTitle && hideActions) {
      return { padding: '32px' };
    }

    if (hideTitle && !hideActions) {
      return { padding: '32px 32px 12px' };
    }

    if (!hideTitle && hideActions) {
      return { padding: '12px 32px 32px' };
    }

    return { padding: '12px 32px' };
  };

  return (
    <Dialog
      {...dialogProps}
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      sx={sx}
      PaperProps={paperProps}
      onClose={emit?.close}
    >
      {renderTitle()}
      <StyledDialogContent
        sx={{
          ...getContentPadding(),
          ...contentSX,
        }}
      >
        {children}
      </StyledDialogContent>
      {renderActions()}
    </Dialog>
  );
}

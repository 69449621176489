import { useSessionContext } from 'src/contexts';

export const usePayments = () => {
  const {
    account: {
      accountRole,
    },
    appConfig: {
      behavior: {
        recruiterBehavior: {
          assessmentPurchasesEnabled,
        },
      },
    },
  } = useSessionContext();

  const isWorkspaceAdmin = accountRole === 'WORKSPACE_ADMIN';
  const isBillingEnabled = isWorkspaceAdmin && assessmentPurchasesEnabled;

  return {
    isBillingEnabled,
  };
};

import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

import { useCallback } from 'react';

import { useSessionContext } from 'src/contexts';
import { useToast } from 'src/hooks';
import { piccIcon } from 'src/utils/config';

import Iconify from '../iconify';

export interface AssessmentLinkButtonProps extends IconButtonProps {
  vanityPath: string;
}

export default function AssessmentLinkButton(props: AssessmentLinkButtonProps) {
  const { color, sx, vanityPath } = props;
  const session = useSessionContext();
  const toast = useToast();

  const { useDemoMode } = session.appConfig.behavior;
  const tooltipTitle = useDemoMode
    ? 'Subscribe to invite candidates'
    : 'Copy assessment link to your clipboard';

  const handleCopyJobLink = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('invited', 'true');
    const jobLink = `https://${window.location.hostname.toLowerCase()}/assessment/${vanityPath}`;
    navigator.clipboard.writeText(`${jobLink}?${searchParams.toString()}`);
    toast.info('Link copied to clipboard');
  }, [toast, vanityPath]);

  if (useDemoMode) {
    return null;
  }

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        color={color}
        sx={sx}
        onClick={handleCopyJobLink}
      >
        <Iconify icon={piccIcon.other.link} />
      </IconButton>
    </Tooltip>
  );
}

import {
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  PaperOwnProps,
  SxProps,
  Theme,
  styled,
} from '@mui/material';

import { ReactNode } from 'react';

export interface SplitCustomDialogEmits {
  close?: () => void;
  confirm?: () => void;
}
export interface SplitCustomDialogProps extends Omit<DialogProps, 'onClose' | 'onConfirm' | 'title'> {
  leftContent: ReactNode;
  leftSize: number;
  rightSize: number;
  rightContent: ReactNode;
  contentSX?: SxProps<Theme>;
  emit?: SplitCustomDialogEmits;
  open: boolean;
}

const StyledDialogContent = styled(DialogContent)(({ sx }) => ({
  '&.MuiDialogContent-root': {
    padding: '32px',
    ...sx,
  },
}));

export default function SplitCustomDialog(props: SplitCustomDialogProps) {
  const {
    leftSize,
    rightSize,
    contentSX,
    emit,
    fullScreen,
    fullWidth,
    leftContent,
    open,
    rightContent,
    sx,
    ...dialogProps
  } = props;

  const paperProps: PaperOwnProps = {
    sx: {
      borderRadius: '20px',
      ...(sx && ('& .MuiDialog-paper' in sx) && {
        '& .MuiDialog-paper': sx['& .MuiDialog-paper'],
      }),
    },
  };

  return (
    <Dialog
      {...dialogProps}
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      sx={sx}
      PaperProps={paperProps}
      onClose={emit?.close}
    >
      <Grid container>
        <Grid item xs={leftSize}>
          <StyledDialogContent
            sx={contentSX}
          >
            {leftContent}
          </StyledDialogContent>
        </Grid>
        <Grid item xs={rightSize}>
          <StyledDialogContent sx={contentSX}>
            {rightContent}
          </StyledDialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
}

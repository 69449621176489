import { Box, Typography, useTheme } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';

import ControlRow from '../control-row';
import CredentialItem from '../partials/credential-item';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabCredentialsForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';

export interface TabCredentialsProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabCredentials(props: TabCredentialsProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const theme = useTheme();

  const methods = useForm<TabCredentialsForm>({
    resolver: yupResolver(formSchemas.tabCredentials),
    defaultValues: applicationFlowContext.application?.profile
      ? formDefaults.tabCredentials(applicationFlowContext.application.profile)
      : undefined,
  });

  const {
    handleSubmit,
    setValue,
    watch,
  } = methods;

  const w = watch();

  const onSubmit = handleSubmit(
    (data: TabCredentialsForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update credentials');
      }

      const updatedCandidateProfile = formValues.onNextCredentials(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileCredentials']({
        credentials: updatedCandidateProfile.credentials,
      });
      onNext();
    },
    (i) => console.error(i),
  );

  if (!applicationFlowContext.jobListing) {
    return (<>Something went wrong! Please refresh and try again.</>);
  }

  return (
    <FormProvider methods={methods}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/education-illustration.svg"
          />
        </Box>

        <QuestionCard>
          <Box display="flex" flexDirection="column" rowGap={1.5}>
            <Typography variant="h3">
              Do you have any of the following?
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2,
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
              }}
            >
              {applicationFlowContext.jobListing.certificates.map((certificate) => (
                <CredentialItem
                  key={certificate.id}
                  certificate={certificate}
                  value={w.certificates[certificate.id] === 5}
                  onChange={(value) => {
                    setValue(
                      `certificates.${certificate.id}`,
                      value ? 5 : 1,
                    );
                  }}
                />
              ))}
            </Box>
            <RHFTextField
              name="notes"
              label="Note to hiring company"
              multiline
              rows={3}
            />
            <Box sx={{ px: 1 }}>
              <Typography variant="body2">
                Selecting ‘Yes’ indicates that you have the above license or certification(s) and
                can provide proof upon request.
              </Typography>
            </Box>
          </Box>
        </QuestionCard>

        <ControlRow
          onBack={onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />
      </Box>

    </FormProvider>
  );
}

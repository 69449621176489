export interface RadioOption {
  label: string;
  value: string;
}

export const genderOptions: RadioOption[] = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];

export const ageOptions: RadioOption[] = [
  { label: 'Under 18', value: 'Under 18' },
  { label: '18-24', value: '18-24' },
  { label: '25-34', value: '25-34' },
  { label: '35-44', value: '35-44' },
  { label: '45-54', value: '45-54' },
  { label: '55+', value: '55+' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
];

export const raceOptions: RadioOption[] = [
  {
    label: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  {
    label: 'Asian',
    value: 'Asian',
  },
  {
    label: 'Black or African American',
    value: 'Black or African American',
  },
  {
    label: 'Hispanic or Latin',
    value: 'Hispanic or Latin',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Prefer not to say',
    value: 'Prefer not to say',
  },
];

import { MenuItem, useTheme } from '@mui/material';

import Iconify from '../iconify';

import { MenuItemConfig } from './model';

export default function CustomMenuItem(props: MenuItemConfig) {
  const {
    disabled,
    icon,
    label,
    onClick,
  } = props;
  const theme = useTheme();

  const iconElement = icon ? <Iconify icon={icon} /> : null;
  return (
    <MenuItem
      key={label}
      disabled={disabled}
      onClick={onClick}
      sx={{
        color: theme.palette.grey[700],
      }}
    >
      {iconElement}
      {label}
    </MenuItem>
  );
}

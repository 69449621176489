import { Box, Button, Typography, useTheme } from '@mui/material';

import Image from 'src/components/image';
import { useSessionContext } from 'src/contexts';

import { SurveyFlowStepProps } from '../model';

export type GettingStartedProps = Omit<SurveyFlowStepProps, 'onPrevious'>;

export default function GettingStarted(props: GettingStartedProps) {
  const { onNext } = props;
  const theme = useTheme();
  const headerMessage = 'Welcome to TEQ Connect!';
  const session = useSessionContext();

  const renderWelcomeImage = () => {
    const image = '/assets/illustrations/candidate-welcome-modal-graphic-1.svg';

    return (
      <Box width="100%" textAlign="center">
        <Image src={image} />
      </Box>
    );
  };

  const renderBodyCopy = () => {
    const firstPart = 'TEQ Connect is a platform that showcases your';
    const highlightedText = 'best traits and skills';
    const secondPart = ', helping employers make smart hiring choices.';
    const {
      useDemographicCollection,
      useDemographicCollectionExtended,
    } = session.appConfig.behavior.candidateBehavior;

    const showOptionalMessage = (
      useDemographicCollection && useDemographicCollectionExtended) || useDemographicCollection;
    const lowerText = 'We need to ask you a couple questions before continuing with the assessment.';

    return (
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Typography sx={{ display: 'inline' }}>
          {firstPart} {' '}
          <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
            {highlightedText}
          </span>
          {secondPart}
        </Typography>
        {showOptionalMessage && <Typography>{lowerText}</Typography>}
      </Box>
    );
  };

  const renderNextButton = () => {
    const buttonText = ' Next';

    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onNext();
          }}
        >
          {buttonText}
        </Button>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={3}
    >
      {renderWelcomeImage()}
      <Typography variant="h3">{headerMessage}</Typography>
      {renderBodyCopy()}
      {renderNextButton()}
    </Box>
  );
}

/* eslint-disable max-len */
import {
  Application,
  AssessmentResponseType,
  CandidateAccount,
  CandidateAssessment,
  CandidateAvailability,
  CandidateCredentials,
  CandidateEducation,
  CandidateEligibility,
  CandidateExperience,
  CandidateLocation,
  CandidateProfile,
  EducationLevel,
  ExperienceType,
  PublicListingForApplication,
  UniformListingDefinition,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

export const initListing = (): PublicListingForApplication => ({
  id: '',
  name: '',
  numAssessmentsRemaining: 0,
  profileId: '',
  profileName: '',
  isGenericProfile: false,
  genericProfileRevision: '',
  genericProfileJobTitle: '',
  credentialsRevision: '',
  templateId: '',
  templateName: '',
  isAssessmentOnly: false,
  publishedDate: new Date(),
  location: '',
  employmentType: '',
  companyName: '',
  companyLocation: '',
  companyWebsite: '',
  companyAbout: '',
  companySize: '',
  companyLogo: '',
  companyTag: '',
  headerImage: '',
  primaryColor: '',
  secondaryColor: '',
  isAvailable: true,
  description: '',
  certificates: [],
  assessmentQuestionIds: ['test-assessment-1', 'test-assessment-2', 'test-assessment-3', 'test-assessment-4'],
  additionalEducation: [],
});

export const initProfile = (): UniformProfileDefinition => ({
  profileName: '',
  profileId: '',
  family: '',
  teqAssessment: {
    questions: {
      allQuestions: [
        {
          id: 'test-assessment-1',
          icon: 'material-symbols:change-circle',
          label: 'Adapting and Coping',
          question: 'Describe an example of when you\'ve had to adapt to difficult changes at work. How did you go about it and what was the outcome?',
          tip: 'Focus on how you respond and adapt to change at work.',
          minWords: 0,
          maxWords: 0,
          minAudioLength: 0,
          maxAudioLength: 0,
        },
        {
          id: 'test-assessment-2',
          icon: 'material-symbols:thumb-up',
          label: 'Agreeableness',
          question: 'Suppose you decided to go out of your way to build a friendly and collaborative relationship with one or more new team members. What actions would you take and what do you think the outcomes would be?',
          tip: 'Talk about how you\'re friendly, sensitive, and trusting in work situations.',
          minWords: 0,
          maxWords: 0,
          minAudioLength: 0,
          maxAudioLength: 0,
        },
        {
          id: 'test-assessment-3',
          icon: 'mdi:magnify-scan',
          label: 'Analyzing and Interpreting',
          question: 'Describe a project you worked on that best demonstrates your ability to solve problems and generate solutions. What problem did you solve and what solution(s) did you generate? What was the result?',
          tip: 'Share your proficiency at identifying and solving complex work problems.',
          minWords: 0,
          maxWords: 0,
          minAudioLength: 0,
          maxAudioLength: 0,
        },
        {
          id: 'test-assessment-4',
          icon: 'material-symbols:list-alt',
          label: 'Conscientiousness',
          question: 'There are times when we work without close supervision and we must make sure the job gets done. Tell me about when you found yourself in such a situation. What happened? What was the result?',
          tip: 'Focus on how you are especially achievement oriented, dependable, and organized at work.',
          minWords: 0,
          maxWords: 0,
          minAudioLength: 0,
          maxAudioLength: 0,
        }],
      sections: [],
    },
    traits: {
      adaptingAndCoping: 1,
      analyzingAndInterpreting: 1,
      creatingAndConceptualizing: 1,
      enterprisingAndPerforming: 1,
      interactingAndPresenting: 1,
      leadingAndDeciding: 1,
      organizingAndExecuting: 1,
      supportingAndCooperating: 1,
      conscientiousness: 1,
      empathy: 1,
      enthusiasm: 1,
      grit: 1,
      opennessToExperience: 1,
      proactiveness: 1,
      agreeableness: 1,
      resilience: 1,
      extraversion: 1,
      selfDetermination: 1,
      sustainedDedication: 1,
      delayedGratification: 1,
      negativeExpressionOfEmotion: 1,
    },
    numberRequired: 0,
    defaultMinWords: 0,
    defaultMaxWords: 0,
    defaultMinAudioLength: 0,
    defaultMaxAudioLength: 0,
    enableFileUpload: false,
  },
  eligibility: {
    fields: [],
  },
  location: {
    fields: [],
  },
  availability: {
    fields: [],
  },
  education: {
    fields: [],
    isCertificationsEnabled: false,
    isAdditionalEducationEnabled: false,
  },
  experience: {
    fields: [],
  },
});

export const initApplication = (): Application => ({
  id: '',
  vendorId: '',
  accountId: '',
  jobProfileId: '',
  jobListingId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  submitted: false,
  submittedAt: new Date(),
  profile: initCandidateProfile(),
});

export const initCandidateProfile = (): CandidateProfile => ({
  isComplete: false,
  completeAt: new Date(),
  account: initCandidateAccount(),
  experience: initCandidateExperience(),
  location: initCandidateLocation(),
  availability: initCandidateAvailability(),
  eligibility: initCandidateEligibility(),
  education: initCandidateEducation(),
  credentials: initCandidateCredentials(),
  assessment: initCandidateAssessment(),
});

export const initCandidateAccount = (): CandidateAccount => ({
  birthMonth: 0,
  birthYear: 0,
});

export const initCandidateExperience = (): CandidateExperience => ({
  isComplete: false,
  experienceLevel: ExperienceType.NoExperience,
  profileAttributes: {},
});

export const initCandidateLocation = (): CandidateLocation => ({
  isComplete: false,
  location: '',
  locationType: ['ONSITE'],
  listingRequiresRelocation_28: 0,
  scheduleWillingToTravel_24: 1,
  profileAttributes: {},
});

export const initCandidateAvailability = (): CandidateAvailability => ({
  isComplete: false,
  employmentType: ['FULL_TIME'],
  positionType: ['PERMANENT'],
  profileAttributes: {},
});

export const initCandidateEligibility = (): CandidateEligibility => ({
  isComplete: false,
  authorizedToWork_12: 0,
  listingAgeRequirement_13: 0,
  profileAttributes: {},
});

export const initCandidateEducation = (): CandidateEducation => ({
  isComplete: false,
  educationLevel: EducationLevel.LessThanHighschoolDiploma,
  additionalEducation: [],
  profileAttributes: {},
});

export const initCandidateCredentials = (): CandidateCredentials => ({
  isComplete: false,
  certificates: {},
  canProvideCertificates: false,
  profileAttributes: {},
});

export const initCandidateAssessment = (): CandidateAssessment => ({
  isComplete: false,
  responseType: AssessmentResponseType.TEXT,
  responses: [''],
  responseAnswers: [],
});

export const toListingForApplication = (form: UniformListingDefinition) => ({
  id: form.id,
  name: form.overview.title,
  profileId: form.profileId,
  profileName: form.profileName,
  isGenericProfile: false,
  genericProfileRevision: '',
  genericProfileJobTitle: '',
  credentialsRevision: '',
  templateId: '',
  templateName: '',
  isAssessmentOnly: false,
  publishedDate: new Date(),
  location: form.overview.location,
  employmentType: form.overview.positionStatus,
  companyName: '',
  companyLocation: '',
  companyWebsite: '',
  companyAbout: '',
  companySize: '',
  companyLogo: '',
  companyTag: '',
  headerImage: '',
  primaryColor: '',
  secondaryColor: '',
  isAvailable: true,
  description: form.overview.description,
  certificates: form.eligibility.certificates,
  assessmentQuestionIds: form.teqQuestions.selectedQuestions,
  additionalEducation: form.eligibility.additionalEducation,
});

import {
  UserAccountAnalyticsExtendedQuestion,
  UserAccountAnalyticsInfo,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import FormProvider, { RHFMultiCheckbox, RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import { useSessionContext } from 'src/contexts';

import { SurveyFlowStepProps } from '../model';
import DemographicsWrapper from '../wrapper';

interface BuildSubmarinesOption {
  label: string;
  value: string;
}

const buildSubmarinesOptions: BuildSubmarinesOption[] = [
  { label: 'TV Commercial', value: 'TV Commercial' },
  { label: 'NASCAR', value: 'NASCAR' },
  { label: 'Career fair', value: 'Career fair' },
  { label: 'Other (please specify)', value: 'Other' },
];

export default function SurveyExtended(props: SurveyFlowStepProps) {
  const { onNext, onPrevious } = props;
  const session = useSessionContext();
  const currentAnswer: UserAccountAnalyticsExtendedQuestion
    = session.account.analyticsInfo.extendedQuestions.length
      ? session.account.analyticsInfo.extendedQuestions[0]
      : {
        question: '',
        answers: [],
      };

  const FormSchema = Yup.object().shape({
    response: Yup.string().required('required'),
    response2: Yup.array(Yup.string().required('required')).required(),
    response3: Yup.string(),
  });

  const defaultValues = {
    response: currentAnswer.answers.length ? currentAnswer.answers[0] : '',
    response2: currentAnswer.answers.length > 1 ? currentAnswer.answers.slice(1) : [],
    response3: currentAnswer.answers.filter((i) =>
      buildSubmarinesOptions.findIndex((j) => j.value === i) === -1,
    ).join(', '),
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const values = methods.watch();

  const handleSubmit = methods.handleSubmit(async (data) => {
    try {
      if (defaultValues.response === data.response
        && defaultValues.response2.join('') === data.response2.join('')
        && defaultValues.response3 === data.response3
      ) {
        onNext();
        return;
      }

      const next: UserAccountAnalyticsExtendedQuestion = {
        question: 'recognition',
        answers: [values.response, ...data.response2],
      };
      if (data.response3) {
        next.answers.push(data.response3);
      }
      const updatedAnalytics: UserAccountAnalyticsInfo = {
        ethnicity: '',
        source: '',
        gender: '',
        age: '',
        extendedQuestions: [next],
      };
      await onNext(updatedAnalytics);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit}
    >
      <Box display="flex" flexDirection="column" rowGap={3}>
        <Box display="flex" flexDirection="column" rowGap={3}>
          <Box display="flex" flexDirection="row" justifyContent="left">
            <Typography variant="h3">
              Build Submarines
            </Typography>
          </Box>
          <Typography>
            The Navy is on a once-in-a-generation journey to completely transform
            its nuclear-powered submarine fleet and maintain its critical
            undersea advantage. This military mandate will require the addition of
            more than 100,000 skilled workers with the training and commitment
            to ensure success.
          </Typography>
        </Box>
        <DemographicsWrapper
          onPrevious={onPrevious}
          isSubmitting={methods.formState.isSubmitting}
          hideHeader
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h5">
              Did you hear about this job through <u>BuildSubmarines.com</u>?
            </Typography>
            <Box sx={{ pl: 1 }}>
              <RHFRadioGroup
                name="response"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
              {values.response === 'Yes' && (
                <Box sx={{ pt: 1 }}>
                  <Typography variant="h6">
                    Tell us where you heard about Build Submarines.
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    Check all that apply:
                  </Typography>

                  <RHFMultiCheckbox
                    name="response2"
                    options={buildSubmarinesOptions}
                    sx={{ width: 1 }}
                  />
                  <Box>
                    <RHFTextField
                      size="small"
                      name="response3"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DemographicsWrapper>
      </Box>
    </FormProvider>
  );
}

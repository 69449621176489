import { useMemo } from 'react';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

import { SurveyFlowStepConfig, SurveyFlowStepId, surveyFlowStepRoute } from './model';
import DemographicsAge from './sub-components/demographics-age';
import DemographicsGender from './sub-components/demographics-gender';
import DemographicsRace from './sub-components/demographics-race';
import GettingStarted from './sub-components/getting-started';
import SurveyExtended from './sub-components/survey-extended';

export const useSurveyFlow = () => {
  const session = useSessionContext();

  const {
    useDemographicCollection,
    useDemographicCollectionExtended,
  } = session.appConfig.behavior.candidateBehavior;

  const surveyFlowStepMap = useMemo(() => {
    const stepMap = new Map<SurveyFlowStepId, SurveyFlowStepConfig>();

    stepMap.set(SurveyFlowStepId.WELCOME, {
      id: SurveyFlowStepId.WELCOME,
      route: surveyFlowStepRoute[SurveyFlowStepId.WELCOME],
      component: GettingStarted,
    });

    if (useDemographicCollectionExtended) {
      stepMap.set(SurveyFlowStepId.SURVEY_EXTENDED, {
        id: SurveyFlowStepId.SURVEY_EXTENDED,
        route: surveyFlowStepRoute[SurveyFlowStepId.SURVEY_EXTENDED],
        component: SurveyExtended,
      });
    }

    if (useDemographicCollection) {
      stepMap.set(SurveyFlowStepId.DEMOGRAPHICS_GENDER, {
        id: SurveyFlowStepId.DEMOGRAPHICS_GENDER,
        route: surveyFlowStepRoute[SurveyFlowStepId.DEMOGRAPHICS_GENDER],
        component: DemographicsGender,
      });

      stepMap.set(SurveyFlowStepId.DEMOGRAPHICS_RACE, {
        id: SurveyFlowStepId.DEMOGRAPHICS_RACE,
        route: surveyFlowStepRoute[SurveyFlowStepId.DEMOGRAPHICS_RACE],
        component: DemographicsRace,
      });

      stepMap.set(SurveyFlowStepId.DEMOGRAPHICS_AGE, {
        id: SurveyFlowStepId.DEMOGRAPHICS_AGE,
        route: surveyFlowStepRoute[SurveyFlowStepId.DEMOGRAPHICS_AGE],
        component: DemographicsAge,
      });
    }

    return stepMap;
  }, [
    useDemographicCollection,
    useDemographicCollectionExtended,
  ]);

  const getInitialSurveyFlowStep = () => surveyFlowStepMap.get(SurveyFlowStepId.WELCOME) || null;

  const getNextStepInOrder = (currentStepId: SurveyFlowStepId) => {
    const steps = Array.from(surveyFlowStepMap.keys());
    const currentIndex = steps.indexOf(currentStepId);

    if (currentIndex === -1 || currentIndex === steps.length - 1) {
      return null;
    }

    const nextStepId = steps[currentIndex + 1];
    return surveyFlowStepMap.get(nextStepId) || null;
  };

  const getBackStepInOrder = (currentStepId: SurveyFlowStepId) => {
    const steps = Array.from(surveyFlowStepMap.keys());
    const currentIndex = steps.indexOf(currentStepId);

    if (currentIndex <= 0) {
      return null;
    }

    const previousStepId = steps[currentIndex - 1];
    return surveyFlowStepMap.get(previousStepId) || null;
  };

  return {
    getInitialSurveyFlowStep,
    getNextStepInOrder,
    getBackStepInOrder,
  };
};
